import { ReactNode } from "react";
import clsx from "clsx";
import Image from "next/image";
import * as Dialog from "@radix-ui/react-dialog";
import Close from "../public/icons/modal-close.svg";
import CloseWhite from "../public/icons/modal-close-white.svg";

export type DialogType = {
  //the element that renders the dialog
  trigger?: ReactNode;

  //content of the dialog
  contentStyle?: React.CSSProperties;
  content?: ReactNode;
  footer?: ReactNode;
  dark?: boolean;
  disabled?: boolean;
  open?: boolean;
  close?: ReactNode;
  setOpen?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((open: boolean) => void);
  dialogContentClassName?: string;
  dialogCloseClassName?: string;
  dialogDescriptionClassName?: string;
  gapContent?: boolean;
  backgroundColorOverride?: string;
};

export const StyledModal: React.FC<DialogType> = ({
  close,
  content,
  contentStyle,
  dark,
  dialogCloseClassName,
  dialogContentClassName,
  dialogDescriptionClassName,
  gapContent = true,
  open,
  setOpen,
  trigger,
  backgroundColorOverride,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      <Dialog.Portal className="bg-alter-black">
        <Dialog.Overlay
          className="bg-alter-black-80"
          style={{ position: "fixed", inset: 0, zIndex: 20 }}
        />
        <Dialog.Content
          className={clsx(
            `p-6 rounded-2xl fixed z-20 min-w-[85vw] md:max-w-[600px] md:min-w-[50vw] overflow-y-scroll max-h-[100dvh] md:max-h-[65vh]`,
            dark
              ? `bg-black text-alter-bone`
              : `bg-alter-bone text-alter-black`,
            dialogContentClassName
          )}
          style={{
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px`,
            backgroundColor: backgroundColorOverride,
            ...contentStyle,
          }}
        >
          <div
            className={clsx("w-full h-full flex flex-row items-start", {
              "gap-[8px]": gapContent,
            })}
          >
            <Dialog.Description
              className={clsx(
                "flex flex-col grow h-full",
                dialogDescriptionClassName
              )}
            >
              {content}
            </Dialog.Description>
            <Dialog.Close className={dialogCloseClassName}>
              {close ?? (
                <button type="button">
                  <Image
                    src={dark ? CloseWhite : Close}
                    alt="close"
                    width={64}
                    height={64}
                    className="min-h-8 min-w-8"
                  />
                </button>
              )}
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default StyledModal;
