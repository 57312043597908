import { useState } from "react";
import StyledModal from "../StyledModal";
import Image from "next/image";
import CloseModal from "../../public/icons/modal-close2.svg";
import { Yotpo } from "../sections/YotpoReviews";

interface Props {
  trigger: React.ReactNode;
}

export const ReviewListModal = ({ trigger }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <StyledModal
      open={open}
      setOpen={setOpen}
      trigger={<div onClick={() => setOpen(true)}>{trigger}</div>}
      close={
        <button>
          <Image
            src={CloseModal}
            alt="close"
            width={20}
            height={20}
            className="absolute top-5 right-5 min-h-5 min-w-5"
          />
        </button>
      }
      contentStyle={{
        backgroundColor: "#FFF",
        width: "100vw",
        height: "100dvh",
      }}
      content={
        <div>
          <Yotpo.InitWidgets />
          <Yotpo.ReviewsList />
        </div>
      }
    />
  );
};
