import MuxPlayer from "@mux/mux-player-react";
import { MediaT } from "../../utils/contentfulTypes";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getTailwindBreakpoints } from "../../utils/misc";
import { useEffect, useState, useRef } from "react";
import { useAnimate, useInView, motion } from "framer-motion";
import Link from "next/link";
import clsx from "clsx";
import Image from "next/image";
import MuxPlayerElement from "@mux/mux-player/.";
import { leadQuizInitiatedAnalytics } from "@/utils/analytics";

interface HeroSectionProps {
  data: MediaT;
}

export const HeroSection = ({ data }: HeroSectionProps) => {
  const [showFallbackImage, setShowFallbackImage] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = !!width && width < getTailwindBreakpoints("md");
  const muxPlayer = useRef<MuxPlayerElement | null>(null);

  const [headerRef, animate] = useAnimate();
  const isInView = useInView(headerRef, {
    amount: "all",
  });

  const attemptPlay = () => {
    muxPlayer?.current
      ?.play()
      .then(() => setShowFallbackImage(false))
      .catch(() => setShowFallbackImage(true));
  };

  useEffect(() => {
    if (isInView) {
      animate(
        headerRef.current,
        { opacity: 1, translateY: 0 },
        {
          duration: 0.5,
          ease: "easeOut",
        }
      );
    }
  }, [isInView]);

  return (
    <div className="relative flex h-screen bg-black p-6 pb-10 text-alter-bone md:h-[calc(100vh-110px)] md:p-20 md:pb-10">
      {!isMobile && (
        <MuxPlayer
          ref={muxPlayer}
          streamType="on-demand"
          playbackId={data.video.playbackId}
          autoPlay="muted"
          loop
          playsInline
          muted
          className={clsx(
            "mux-cover mux-player absolute left-0 top-0",
            showFallbackImage && "hidden"
          )}
          onSuspend={() => attemptPlay()}
          onCanPlay={() => setShowFallbackImage(false)}
        />
      )}
      <Image
        src={isMobile ? data.imageMobile.url : data.imageDesktop.url}
        alt="Person using the Alter System"
        fill
        className={clsx(
          "object-cover",
          showFallbackImage || isMobile ? "opacity-100" : "opacity-0"
        )}
      />
      <div className="relative w-full width-1440 mt-auto flex flex-col gap-6 lg:flex-row lg:gap-32 lg:justify-between">
        <div className="">
          <div>
            <motion.h1
              ref={headerRef}
              className="modernGothicTrial-light text-[28px] leading-tight md:max-w-[35ch] md:text-[36px]"
              initial={{ opacity: 0, translateY: 60 }}
            >
              {data.headline}
            </motion.h1>
            <Link
              href="/demo"
              className="block button-light w-max mt-4 text-base md:mt-10"
              onClick={() => leadQuizInitiatedAnalytics()}
            >
              Try the virtual demo
            </Link>
          </div>
        </div>
        <div className="h-max p-8 rounded-2xl backdrop-blur-md bg-alter-bone-15 max-w-[380px]">
          <span className="block mb-6 font-light md:text-xl">
            “You can have confidence you’re going to get results”
          </span>
          <Image
            src={"/logos/cbs-news-white.png"}
            alt="CBS news"
            width={154}
            height={23}
            className="w-[107px] h-4 md:w-[154px] md:h-[23px]"
          />
        </div>
      </div>
    </div>
  );
};
