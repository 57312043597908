import React, { useEffect } from "react";
import clsx from "clsx";
import { useAnimate, useInView, motion } from "framer-motion";
import { ReviewListModal } from "../modals/ReviewListModal";

export const YotpoProductId = {
  swabKit: "7396533633201",
} as const;

const InitWidgets = () => {
  React.useEffect(() => {
    if (window && window.yotpoWidgetsContainer?.initWidgets) {
      // window.yotpoWidgetsContainer?.guids[
      //   "3KobvdbQ4mJiMN8edw7SGIRXxcZvLrklsYTVW2Mu"
      // ]?.initWidgets();
      // console.log(
      //   window.yotpoWidgetsContainer?.guids[
      //     "3KobvdbQ4mJiMN8edw7SGIRXxcZvLrklsYTVW2Mu"
      //   ]?.initWidgets()
      // );
      window.yotpoWidgetsContainer.initWidgets();
      // window.yotpoWidgetsContainer.loadFromElement(ref.current, true);
      // console.log(window);
    }
  }, []);

  return <></>;
};

const ReviewsList = ({ className }: { className?: string }) => {
  return (
    <div
      id="reviews"
      className="yotpo-widget-instance relative"
      data-yotpo-instance-id="356355"
      data-yotpo-product-id={YotpoProductId.swabKit}
      data-yotpo-name="Alter DNA kit and DNA report"
      data-yotpo-url="{{ shop.url }}{{ product.url }}"
      data-yotpo-image-url="{{ product.featured_image | product_img_url: 'large' | prepend: 'https:' | replace: '?', '%3F' | replace: '&','%26'}}"
      data-yotpo-price="{{ product.price | divided_by: 100.00 }}"
      data-yotpo-currency="{{ shop.currency }}"
      data-yotpo-description="{{ product.description | escape }}"
    />
  );
};

const ReviewsCarousel = ({ className }: { className?: string }) => {
  return (
    <div
      id="reviews"
      className={"yotpo-widget-instance bg-alter-bone relative " + className}
      data-yotpo-instance-id="600129"
      data-yotpo-product-id={YotpoProductId.swabKit}
      data-yotpo-cart-product-id="{{item.product.id}}"
    />
  );
};

export interface ProductReviewsBottomLine {
  average_score: number;
  total_reviews: number;
}

/**
 * Fetches review metadata from Yotpo.
 * @param productId The Yotpo product ID for which to fetch review metadata.
 * @returns The review metadata.
 */
export const fetchReviewsMetadata = async (
  productId: string
): Promise<ProductReviewsBottomLine | null> => {
  try {
    const appId = process.env.NEXT_PUBLIC_YOTPO_APP_ID;
    const fetchURL = `https://api.yotpo.com/products/${appId}/${productId}/bottomline`;
    const response = await fetch(fetchURL);
    const data = await response.json();
    return (data?.response?.bottomline as ProductReviewsBottomLine) ?? null;
  } catch (error) {
    console.error("Failed to fetch Yotpo reviews metadata:", error);
    return null;
  }
};

export const Reviews = ({
  className,
  titleClassName,
}: {
  className?: string;
  titleClassName?: string;
}) => {
  const [ref, animate] = useAnimate();
  const isInView = useInView(ref, {
    amount: "some",
  });

  useEffect(() => {
    if (isInView) {
      animate(
        ref.current,
        { opacity: 1, translateX: 0 },
        {
          duration: 1,
          ease: "easeOut",
        }
      );
    }
  }, [isInView]);

  return (
    <section className="relative md:bg-[#F6F6F1]">
      <div
        className={clsx(
          "pt-[100px] pb-10 md:py-20 bg-white [&_*]:bg-white overflow-hidden md:rounded-3xl",
          className
        )}
      >
        <div className="flex flex-col justify-center items-center">
          <h2
            className={clsx(
              "max-w-[14ch] text-center modernGothicTrial-light text-alter-black text-[28px] leading-snug md:max-w-none md:text-[48px]",
              titleClassName
            )}
          >
            See what others are saying
          </h2>
          <ReviewListModal
            trigger={<Stars className="mt-4 cursor-pointer" />}
          />
        </div>
        <motion.div ref={ref} initial={{ opacity: 0, translateX: 160 }}>
          <ReviewsCarousel className="mt-16" />
        </motion.div>
        <ReviewListModal
          trigger={
            <button className="block button-outline-dark text-base mx-auto mt-10 md:mt-16">
              See all reviews
            </button>
          }
        />
      </div>
    </section>
  );
};

const Stars = ({ className }: { className?: string }) => {
  return (
    <div
      className={"yotpo-widget-instance " + className}
      data-yotpo-instance-id="356354"
      data-yotpo-product-id={YotpoProductId.swabKit}
    />
  );
};

export const Yotpo = {
  InitWidgets,
  Reviews,
  ReviewsList,
  Stars,
};
